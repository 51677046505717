<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ title }}
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>

      <div class="card p-fluid w-full" style="max-width: 550px">
        <h5 class="mb-4">Novo cliente</h5>

        <Toast />

        <div class="">
          <div class="field">
            <label for="campo">Campo</label>
            <Dropdown
              id="campo"
              v-model="form.campo"
              :options="fields"
              optionLabel="text"
              placeholder="Escolha um campo"
            ></Dropdown>
          </div>

          <div class="field">
            <label for="operacao">Operação</label>
            <div
              v-for="operation of operations"
              :key="operation.value"
              class="field-radiobutton my-2"
            >
              <RadioButton
                :id="operation.value"
                name="Operação"
                :value="operation.value"
                v-model="form.operacao"
              ></RadioButton>
              <label :for="operation.value">{{ operation.text }}</label>
            </div>
          </div>

          <div class="field w-6">
            <label for="valor">Valor</label>
            <InputNumber
              id="valor"
              v-model="form.valor"
              prefix="R$ "
              mode="decimal"
              locale="pt-BR"
              :minFractionDigits="2"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !form.valor }"
            />
            <small class="p-error" v-if="submitted && !form.valor"
              >Valor é obrigatório.</small
            >
          </div>

          <div class="field w-6">
            <label for="desconto">Percentual de desconto</label>
            <InputNumber
              id="desconto"
              v-model="form.percentualDesconto"
              suffix="%"
              required="true"
              :class="{ 'p-invalid': submitted && !form.percentualDesconto }"
            />
            <small class="p-error" v-if="submitted && !form.percentualDesconto"
              >Percentual de desconto é obrigatório.</small
            >
          </div>
        </div>

        <div class="flex mt-5">
          <Button
            label="Salvar"
            icon="pi pi-check"
            class="p-button-primary w-6"
            @click="saveProduct"
          />
          <Button
            label="Voltar"
            icon="pi pi-times"
            class="p-button-text w-3 ml-auto"
            @click="
              $router.push(`/clientes/${this.$route.params.client}/regras`)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from '@/services/crud'
import { fields, operations } from '@/services/translate'

export default {
  data() {
    return {
      form: {},
      fields: fields,
      operations: operations,
      submitted: false
    }
  },
  async mounted() {
    this.$service = new CrudService(
      `/admin/clients/${this.$route.params.client}/rules`
    )
    if (this.$route.params.id) {
      const { data } = await this.$service.findById(this.$route.params.id)
      this.form = data
    }
  },
  computed: {
    title() {
      return this.$route.params.id ? 'Editar regra' : 'Adicionar regra'
    }
  },
  methods: {
    async save() {
      this.submitted = true
      try {
        await this.$service.save(this.form)
        this.$toast.add({
          severity: 'success',
          summary: 'Regra gravada com sucesso!',
          life: 3000
        })
        this.$router.go(-1)
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Problemas ao armazenar a regra!',
          life: 3000
        })
        console.log(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
